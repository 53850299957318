'use client'
import heroSvg from '@jobmojito/admin-app/(landing)/landing/images/background-faqs.jpg'
import PageCard from '@jobmojito/admin-app/components/core/PageCard'
import { Link } from '@nextui-org/link'
import Image from 'next/image'
import React from 'react'
import { MerchantType } from './RegisterMain'
import { RegisterSelector } from './registerSelector'
import { RegisterStep1 } from './registerStep1Interview'
import { RegisterStep2 } from './registerStep2'
import { RegisterStep3 } from './registerStep3'
import { RegisterStepSubscribe } from './registerStepSubscribe'
import { StepsComponent } from './steps'
import BlockTitle from '@jobmojito/admin-app/components/core/BlockTitle'

export default function RegisterInterview({
  goToSubscribe = false,
  onChange,
  subMerchant,
  name,
  email,
}: {
  goToSubscribe?: boolean
  onChange: (range: MerchantType) => void
  subMerchant: boolean
  name?: string
  email?: string
}) {
  const [currentStep, setCurrentStep] = React.useState(1)
  const [nextStepAvailable, setNextStepAvailable] = React.useState(true)

  function processNextStep() {
    setCurrentStep(currentStep + 1)
  }

  function processStepBack(step: number) {
    setCurrentStep(step)
  }

  return (
    <div
      className={
        'w-full flex flex-col items-center relative ' +
        (subMerchant === false ? '' : '')
      }
    >
      <Image
        className={'pointer-events-none'}
        alt="Background Image"
        src={heroSvg}
        fill
        style={{
          objectFit: 'contain',
          scale: 1,
        }}
      />
      <PageCard isVisible={!subMerchant}>
        <div className="flex flex-col justify-left text-left gap-4">
          {goToSubscribe ? (
            <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
              <div className='col-span-3'><RegisterStepSubscribe /></div>
              <div className='border-l-2 border-slate-100 pl-5 flex flex-col gap-2'>
                <BlockTitle
                  title='Unlock full platform'
                  description='How full plan works'
                  contentBottom={<div className="w-full text-left text-black ">
                    We offer multiple options for accessing the full version of our interview platform:
  
                    <ul className="list-disc ml-5 my-5 gap-2 flex flex-col text-slate-600 text-sm">
                      <li><span className='underline'>Free</span>: 5 interview results at no cost</li>
                      <li><span className='underline'>One-time Interview</span>: Get the full platform version for a single job position by selecting the "One-time" tab.</li>
                      <li><span className='underline'>Subscription Quarterly</span>: Transition to our quarterly or yearly subscription program when you're ready.</li>
                      <li><span className='underline'>Post-paid</span>: We provide post-paid plans for large technical partners. <Link href="https://jobmojito.com/support" target="_blank">Contact us</Link> for further details.</li>
                    </ul>
  
                    If you have any special requests or wish to chat with us, <Link href="https://jobmojito.com/support" target="_blank">reach us here</Link>.
                  </div>}
                />
              </div>
            </div>
          ) : (
            <>
              {currentStep === 1 ? (
                <RegisterSelector
                  type="interview"
                  onChange={(type) => {
                    onChange(type)
                  }}
                />
              ) : (
                <StepsComponent
                  currentStep={currentStep}
                  changeStep={processStepBack}
                  type="interview"
                />
              )}

              {currentStep === 1 && (
                <RegisterStep1
                  completedStep={() => {
                    processNextStep()
                  }}
                  subMerchant={subMerchant}
                />
              )}
              {currentStep === 2 && (
                <RegisterStep2
                  completedStep={() => {
                    processNextStep()
                  }}
                  type="interview"
                  subMerchant={subMerchant}
                  name={name}
                  email={email}
                />
              )}
              {/*{((currentStep===3) && <RegisterStepSubscribe /> )}*/}
              {currentStep === 3 && <RegisterStep3 />}
            </>
          )}
        </div>
      </PageCard>
    </div>
  )
}
